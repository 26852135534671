<template>
  <div class="cancel-warning">
    <p class="cancel-warning__title">退会の注意事項を確認してください<span class="cancel-warning__title__required">*</span></p>
    <ul class="cancel-warning__list">
      <li class="cancel-warning__list__text">本アプリケーションにアクセスできなくなります。</li>
      <li class="cancel-warning__list__text">退会後、LDW限定Facebookグループよりスタッフが脱退処理を行います。</li>
      <li class="cancel-warning__list__text" v-if="subscription">{{ year }}年{{ month }}月{{ day }}日にお支払いただいた月額費用については一切の返金を行っておりません。</li>
    </ul>
    <div class="cancel-warning__consent">
      <v-icon class="cancel-warning__consent__icon--active" v-if="isCheck" @click="emitData(false)">check_box</v-icon>
      <v-icon class="cancel-warning__consent__icon" v-else @click="emitData(true)">check_box_outline_blank</v-icon>
      <p class="cancel-warning__consent__text">上記の内容に承諾します。</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 承諾済みかどうか
      isCheck: false
    }
  },
  computed: {
    /**
     * @return {Object} サブスクリプション情報
     */
    subscription () {
      return this.$store.getters['subscriptions/subscription']
    },
    /**
     * @return {Date} 最新の支払い年月日
     */
    latestPaymentAt () {
      return this.subscription.completedAt.toDate()
    },
    /**
     * @return {Number} 支払い年
     */
    year () {
      return this.latestPaymentAt.getFullYear()
    },
    /**
     * @return {String} 支払い月
     */
    month () {
      return ('0' + (this.latestPaymentAt.getMonth() + 1)).slice(-2)
    },
    /**
     * @return {String} 支払い日
     */
    day () {
      return ('0' + this.latestPaymentAt.getDate()).slice(-2)
    }
  },
  methods: {
    /**
     * 親コンポーネントへデータを渡す
     * @param {String} value
     */
    emitData (value) {
      this.isCheck = value
      this.$emit('set-check-box', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/fontfamily.scss';

.cancel-warning {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.2rem;
  line-height: 1.5rem;
  &__title {
    margin: 0;
    font-weight: bold;
    &__required {
      color: $red_color;
    }
  }
  &__list {
    padding-left: 0;
    margin-top: 10px;
    list-style: none;
    &__text {
      margin-top: 5px;
      &::before {
        content: "・";
      }
    }
  }
  &__consent {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    &__icon {
      width: 20px;
      height: 20px;
      font-family: $material-outlined;
      color: $black_color;
      &.v-icon {
        font-size: 2rem;
      }
      &--active {
        @extend .cancel-warning__consent__icon;
        color: $orange_color;
      }
    }
    &__text {
      padding: 0 10px;
      margin: 0;
    }
  }
}
</style>
